import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout/Layout'
import BannerImage from '../../components/Page/BannerImage'
import EventsPage from '../../components/Page/EventsPage'

const events = ({data}) => {

	const eventsData = {
		main: data.mainFestivalEvents.nodes,
		childrens: data.childrensFestivalEvents.nodes,
		schools: data.schoolsFestivalEvents.nodes
	}

	return (
		<Layout>
			<BannerImage image={data.bannerImage.childImageSharp.gatsbyImageData} />
			<EventsPage
				eventsData={eventsData}
				festivalType={`main`}
				festivalYear={2023}
				pageHeading={`2023 Boswell Book Festival Events`}
				showAllEventsOption={true}
				showDayFilter={true}
				showEventDate={true}
				showTypeFilter={true}
				useLinksForTypeFilter={true}
			/>
		</Layout>
	)
}

export const query = graphql`
query {
	mainFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"main"}, datetime: {gt:"2023-01-01 00:00", lt:"2024-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
		nodes {
			id
			ft_id
			name
			uuid
			datetime
			date:datetime(formatString:"YYYY-MM-DD")
			time:datetime(formatString:"h:mm a")
			description
			eticket_content
			ticket_content
			valid
			img_path
			local_event_img {
				childImageSharp {
					gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
				}
			}
		}
	}
		childrensFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"childrens"}, datetime: {gt:"2023-01-01 00:00", lt:"2024-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
		nodes {
			id
			ft_id
			name
			uuid
			datetime
			date:datetime(formatString:"YYYY-MM-DD")
			time:datetime(formatString:"h:mm a")
			description
			eticket_content
			ticket_content
			valid
			img_path
			local_event_img {
				childImageSharp {
					gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
				}
			}
		}
	}
	schoolsFestivalEvents: allFutureTicketingEvent(filter: {category: {eq:"schools"}, datetime: {gt:"2023-01-01 00:00", lt:"2024-01-01 00:00 "}}, sort:{fields: datetime, order:ASC}) {
		nodes {
			id
			ft_id
			name
			uuid
			datetime
			date:datetime(formatString:"YYYY-MM-DD")
			time:datetime(formatString:"h:mm a")
			description
			eticket_content
			ticket_content
			valid
			img_path
			local_event_img {
				childImageSharp {
					gatsbyImageData(placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
				}
			}
		}
	}
	bannerImage: file(relativePath: {eq: "general/Bozzy-16-9-001.jpg"}) {
		childImageSharp {
		  gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
		}
	}
}
`

export default events
